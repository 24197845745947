//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Hamburger menu
const $body = document.querySelector('body');
const $hamburger = document.querySelector('.site-hamburger');

$hamburger &&
	$hamburger.addEventListener('click', (e) => {
		$body.classList.toggle('nav-open');
	});

const $formTrigger = document.querySelector('.box-form-trigger');
const $formClose = document.querySelector('.box-close');
$formTrigger &&
	$formTrigger.addEventListener('click', (e) => {
		e.currentTarget.parentNode.dataset.active = true;
	});
$formClose &&
	$formClose.addEventListener('click', (e) => {
		e.currentTarget.parentNode.dataset.active = false;
	});

jQuery(document).ready(function ($) {
	const inputs =
		'.gfield input:not([type=checkbox]), .gfield textarea, .gfield select';

	$('body').on('focusin focusout', inputs, function (event) {
		if (event.type === 'focusin') {
			$(this).parent().parent().addClass('gfield--active');
		} else if (event.type === 'focusout') {
			if ($(this).val() === '') {
				$(this).parent().parent().removeClass('gfield--active');
			}
		}
	});

	if ($('.gfield_select option:selected').val() !== '') {
		$('.gfield_select option:selected')
			.parent()
			.parent()
			.parent()
			.addClass('gfield--active');
	}

	$(document).on('gform_post_render', function () {
		//alert( 'hello' );

		$(inputs).each(function () {
			let $that = $(this);

			if ($that.val() !== '') {
				$that.parent().parent().addClass('gfield--active');
			}
		});
	});

	// CUSTOM SCRIPTS KOMEN HIER ONDER --------------------------------------------------------------------------//
	//  ------------------------------------------------------------------------------------------------------------------------------//

	$('.certificaat-trigger').on('click', function (e) {
		$(this).parent().toggleClass('closed');
	});
});
